<template>
	<div class="animated fadeIn">
		<div class="container">
			<div>
				<b-form
					@submit.prevent="requestNewEventTicket(form)"
					@keydown="clearError($event.target.name);"
					@change="clearError($event.target.name);">
					<div class="form-group">
						<search
							id="username"
							v-model="form.username"
							:label-text="translate('username')"
							:endpoint="endpoint"
							required
							:empty-result-message="translate('data_not_found')"
							:default-params="{role:'distributor'}"
							:custom-error="hasError('username') ? errors['username'][0] : false"
							:init-value="form.username"
							q="label"
							return-key="username"
							@input="clearError('username')" />
					</div>

					<div class="form-group">
						<label
							for="event_code_name"
							class="label">{{ translate('event') }}
							<span class="text-danger">*</span></label>
						<select
							id="event_code_name"
							v-model="form.event_code_name"
							name="event_code_name"
							:class="hasError('event_code_name') ? 'is-invalid' : ''"
							class="form-control">
							<option value="">
								{{ translate('select_event') }}
							</option>
							<option
								v-for="event in events"
								:key="event"
								selected
								:value="event">
								{{ translate(event) }}
							</option>
						</select>
						<template v-if="hasError('event_code_name')">
							<span
								v-for="error in errors['event_code_name']"
								:key="error"
								class="invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>

					<input-text
						id="first_name"
						:label="translate('first_name')"
						:setter-value="form.first_name"
						:errors="errors['first_name']"
						:required="true"
						type="text"
						@dataChanged="form.first_name = $event" />

					<input-text
						id="last_name"
						:label="translate('last_name')"
						:setter-value="form.last_name"
						:errors="errors['last_name']"
						:required="true"
						type="text"
						@dataChanged="form.last_name = $event" />

					<input-text
						id="order_id"
						:label="translate('order_id')"
						:setter-value="form.order_id"
						:errors="errors['order_id']"
						:required="false"
						type="text"
						@dataChanged="form.order_id = $event" />

					<button
						:disabled="Object.keys(errors).length > 0"
						class="btn btn-primary float-right">
						{{ translate('save') }}
					</button>
				</b-form>
			</div>
		</div>
	</div>
</template>
<script>

import Ticket from '@/util/EventTickets';
import InputText from '@/components/InputText';
import { EventTickets, Events } from '@/translations';
import { SEARCH_USERS } from '@/config/endpoint';
import { AVAILABLE_EVENTS } from '@/settings/Events';

export default {
	name: 'NewEventTicket',
	messages: [EventTickets, Events],
	components: {
		InputText,
	},
	data() {
		return {
			ticket: new Ticket(),
			form: {
				event_code_name: '',
				username: '',
				first_name: '',
				last_name: '',
				order_id: null,
			},
			alert: new this.$Alert(),
			endpoint: SEARCH_USERS.endpoint,
			events: AVAILABLE_EVENTS,
			errors: {},
		};
	},
	mounted() {
		this.setFocus('username');
	},
	methods: {
		requestNewEventTicket(payload) {
			this.ticket.createTicket(payload).then(() => {
				this.alert.toast('success', this.translate('ticket_created'));
				this.$router.push({ name: 'EventTicketsAll' });
			}).catch(() => {
				this.errors = { ...this.ticket.data.errors.errors };
				this.form = { ...payload };
			});
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
